<template>
  <el-container>
    <el-container>
      <el-main>
        <div
          style="display: flex; align-items: flex-end; justify-content: center"
        >
          <div
            style="
              display: flex;
              align-items: flex-start;
              flex-direction: column;
            "
          >
            <div class="head_nav">
              <h2>Facebook Ads 配置文件生成器</h2>
            </div>
            <el-form
              :ref="`ruleForm${activeKey}`"
              label-position="left"
              label-width="200px"
              size="mini"
              :rules="rules"
              :model="ruleForm"
            >
              <div class="mask_nav" v-if="showMask"></div>
              <el-form-item label="转化目标" label-align="right">
                <el-radio-group v-model="ruleForm.isApp" size="mini">
                  <el-radio-button label="H5"></el-radio-button>
                  <el-radio-button label="APP"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="命名规则">
                <el-radio-group
                  v-model="ruleForm.channel"
                  size="mini"
                  @change="changeChannel"
                >
                  <el-radio-button label="UnityMobi"></el-radio-button>
                  <el-radio-button label="UMTech"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="预算类型">
                <el-radio-group v-model="ruleForm.campaign_type" size="mini" @change="campaignTypeChange">
                  <el-radio-button label="广告组预算"></el-radio-button>
                  <el-radio-button label="广告系列预算"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="ruleForm.isApp === 'APP'" label="优化目标">
                <el-radio-group
                  v-model="ruleForm.optimization_goal"
                  size="mini"
                >
                  <el-radio-button
                    label="OFFSITE_CONVERSIONS"
                  ></el-radio-button>
                  <el-radio-button label="APP_INSTALLS"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="campaign_name" label="广告系列名称">
                <el-input
                  v-model="ruleForm.campaign_name"
                  placeholder="请输入广告系列名称"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="campaign_daily_budget"
                label="广告系列预算"
                v-if="ruleForm.campaign_type === '广告系列预算'"
              >
                <el-input
                  v-model="ruleForm.campaign_daily_budget"
                  placeholder="请输入广告系列预算"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="ad_set_daily_budget"
                label="广告组预算"
                v-if="ruleForm.campaign_type === '广告组预算'"
              >
                <el-input
                  v-model="ruleForm.ad_set_daily_budget"
                  placeholder="请输入广告组每日预算"
                ></el-input>
              </el-form-item>
              <el-form-item prop="ad_set_CPA" label="手动出价">
                <el-input
                  v-model="ruleForm.bid_amount"
                  placeholder="请输入手动出价金额"
                ></el-input>
              </el-form-item>
              <el-form-item prop="ad_set_name" label="广告组名称">
                <el-input
                  v-model="ruleForm.ad_set_name"
                  placeholder="请输入广告组名称"
                ></el-input>
              </el-form-item>
              <el-form-item prop="ad_account" label="选择广告账户">
                <el-radio-group
                  v-model="ruleForm.ad_account"
                  size="mini"
                  @change="changeAccount"
                >
                  <el-radio-button
                    v-for="(item, index) in accountsConfig.ad_account"
                    :label="item.id"
                    :key="index"
                  >
                    {{ item.name }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="use_dynamic_creative" label="素材类型">
                <el-radio-group
                  v-model="ruleForm.use_dynamic_creative"
                  size="mini"
                  :disabled="dynamic_dis"
                  @change="changeType"
                >
                  <el-radio-button label="Image"></el-radio-button>
                  <el-radio-button label="Video"></el-radio-button>
                  <el-radio-button label="Dynamic"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="link_object_id" label="选择主页">
                <el-radio-group
                  v-model="ruleForm.link_object_id"
                  size="mini"
                  @change="changeAccount"
                >
                  <el-radio-button
                    v-for="(item, index) in accountsConfig.pages"
                    :label="item.id"
                    :key="index"
                  >
                    {{ item.name }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="link" label="推广链接">
                <el-input
                  v-model="ruleForm.link"
                  placeholder="请输入投放链接"
                ></el-input>
              </el-form-item>
              <el-form-item prop="countries" label="选择国家">
                <el-radio-group
                  v-model="ruleForm.countries"
                  size="mini"
                  @change="changeCountries"
                >
                  <el-radio-button label="ID"></el-radio-button>
                  <el-radio-button label="MM"></el-radio-button>
                  <el-radio-button label="PK"></el-radio-button>
                  <!--                  <el-radio-button label="TR"></el-radio-button>-->
                  <!--                  <el-radio-button label="BD"></el-radio-button>-->
                  <!--                  <el-radio-button label="BR"></el-radio-button>-->
                  <!--                  <el-radio-button label="IN"></el-radio-button>-->
                  <el-radio-button label="VN"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="gender" label="性别">
                <el-radio-group v-model="ruleForm.gender" size="mini">
                  <el-radio-button label="All"></el-radio-button>
                  <el-radio-button label="Men"></el-radio-button>
                  <el-radio-button label="Women"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="年龄">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                  "
                >
                  <el-form-item prop="minAge" style="margin-bottom: 0">
                    <el-input
                      v-model="ruleForm.minAge"
                      style="width: 50px"
                      :min="1"
                      :max="100"
                      label="最小年龄"
                    ></el-input>
                  </el-form-item>
                  <span style="color: darkgray">—</span>
                  <el-form-item prop="maxAge" style="margin-bottom: 0">
                    <el-input
                      v-model="ruleForm.maxAge"
                      style="width: 50px"
                      :min="ruleForm.minAge"
                      :max="100"
                      label="最大年龄"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item prop="Flexible_inclusions" label="兴趣定位">
                <el-select
                  v-model="ruleForm.flexible_inclusions"
                  style="width: 100%"
                  multiple
                  placeholder="请选择兴趣定位"
                >
                  <el-option
                    v-for="item in interests"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="publisher_platforms" label="展示平台">
                <el-select
                  v-model="ruleForm.publisher_platforms"
                  style="width: 100%"
                  multiple
                  placeholder="请选择发布平台"
                >
                  <el-option label="facebook" value="facebook"></el-option>
                  <el-option label="instagram" value="instagram"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="call_to_action" label="行动目标">
                <el-radio-group v-model="ruleForm.call_to_action" size="mini">
                  <el-radio-button label="DOWNLOAD"></el-radio-button>
                  <el-radio-button label="PLAY_GAME"></el-radio-button>
                  <el-radio-button label="GET_OFFER"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                prop="dynamic_creative_call_to_action"
                label="动态行动目标"
                v-if="ruleForm.use_dynamic_creative === 'Dynamic'"
              >
                <el-radio-group
                  v-model="ruleForm.dynamic_creative_call_to_action"
                  size="mini"
                >
                  <el-radio-button label="DOWNLOAD"></el-radio-button>
                  <el-radio-button label="PLAY_GAME"></el-radio-button>
                  <el-radio-button label="GET_OFFER"></el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                width: 100%;
                margin-top: 12px;
              "
            >
              <el-upload
                v-if="Object.keys(accountsConfig).length === 0"
                class="upload-demo"
                style="margin: 0 10px"
                action="#"
                accept=".json"
                :auto-upload="false"
                :on-change="uploadAccountsConfig"
              >
                <el-button plain size="small">
                  <i class="el-icon-upload"></i>
                  <span>上传账户配置(json)</span>
                </el-button>
              </el-upload>
              <el-button
                v-else
                style="margin: 0 10px"
                type="text"
                @click="showConfigs = true"
                plain
                size="small"
              >
                <i class="el-icon-upload"></i>
                <span>查看账户配置</span>
              </el-button>
              <el-upload
                v-if="adAssets.length === 0"
                class="upload-demo"
                action="#"
                accept=".json"
                :auto-upload="false"
                :on-change="uploadAssetsConfig"
              >
                <el-button plain size="small">
                  <i class="el-icon-upload"></i>
                  <span>上传素材资源（json）</span>
                </el-button>
              </el-upload>
            </div>
          </div>
          <el-aside style="margin-left: 24px" v-if="adAssets.length > 0">
            <div class="resource_nav_out">
              <div class="resource_btn_nav">
                <div style="display: flex; flex-direction: row">
                  <el-button
                    @click.prevent="increase_campaign_name"
                    size="small"
                    type="primary"
                    icon="el-icon-plus"
                  >
                    Campaign
                  </el-button>
                  <el-button
                    @click.prevent="increase_ad_set_name"
                    size="small"
                    type="primary"
                    icon="el-icon-plus"
                  >
                    Ad Set
                  </el-button>
                  <el-button
                    @click.prevent="exportCSV"
                    size="small"
                    type="success"
                    icon="el-icon-download el-icon--right"
                  >
                    导出
                  </el-button>
                </div>
                <i
                  class="el-icon-brush cu_pointer"
                  style="margin: 0 20px"
                  @click="clearSelectResource"
                ></i>
                <i
                  class="el-icon-document-add cu_pointer"
                  @click="add_advert"
                ></i>
              </div>
              <div class="resource_content_nav">
                <div class="resource_nav_left">
                  <span
                    v-for="(item, index) of adAssets"
                    :key="index"
                    :class="[selectAssetsActiveKey === index ? 'active' : '']"
                    @click="selectResourceItem(index)"
                  >
                    {{ item.date }}
                  </span>
                </div>
                <div class="resource_nav_list">
                  <div
                    v-for="(item, index) in selectAssetsActiveItem"
                    :key="`e${index}`"
                    v-show="
                      ruleForm.use_dynamic_creative === 'Dynamic'
                        ? true
                        : ruleForm.use_dynamic_creative === item.type
                    "
                    class="resource_nav"
                    @click="addResource(item)"
                  >
                    <div class="mask_nav" v-if="!resource_mask(item)"></div>
                    <div v-if="item.isActive" class="show_ad_name">
                      <span>{{ item.ad_name }}</span>
                    </div>
                    <el-image
                      class="img_class cu_pointer"
                      :src="item.thumbnail"
                    >
                    </el-image>
                    <span>{{ item.name }}</span>
                    <span>{{
                      item.type === "Video" ? item.video_id : item.id
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-aside>
        </div>
        <div id="hot-container" style="margin-top: 20px"></div>
      </el-main>
      <!-- show configJson -->
      <el-drawer :visible.sync="showConfigs" direction="rtl" size="40%">
        <vue-json-editor
          style="height: 100%"
          v-model="accountsConfig"
          :showBtns="false"
          :mode="'code'"
          @json-change="onJsonChange"
          @json-save="onJsonSave"
          @has-error="onError"
        />
      </el-drawer>
    </el-container>
  </el-container>
</template>
<script>
import vueJsonEditor from "vue-json-editor";
import {
  defaultData,
  dynamicData,
  imageData,
  videoData,
  appData,
} from "@/assets/deaultData";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";
// import resource_json from "@/assets.json";
// import config_json from "@/test.json";
export default {
  components: {
    vueJsonEditor,
  },
  data() {
    const checkUrl = (rule, value, callback) => {
      let rep = /^https:\/\//;

      if (!rep.test(value)) {
        return callback(new Error("输入链接有误"));
      } else {
        callback();
      }
    };
    const checkAge = (rule, value, callback) => {
      if (value < this.ruleForm.minAge) {
        return callback(new Error("最大年龄不能小于最小年龄"));
      } else {
        callback();
      }
    };
    const checkResource = (rule, value, callback) => {
      if (
        this.ruleForm.use_dynamic_creative !== "Dynamic" &&
        this.ruleForm.resource.length < 1
      ) {
        return callback(new Error("请选择资源"));
      } else if (
        this.ruleForm.use_dynamic_creative === "Dynamic" &&
        this.ruleForm.resource.length < 2
      ) {
        return callback(new Error("请选择至少两个资源"));
      } else {
        callback();
      }
    };
    return {
      accountsConfig: {}, // 账户配置文件
      adAssets: [], // 广告素材列表
      selectAssetsActiveKey: 0, // 资源列表当前选中的key
      selectAssetsActiveItem: [], // 资源列表当前选中的item
      adLists: [], // 广告列表
      handleTable: null, // 表格实例
      lastAdName: "", // 最后一个广告名称
      lastAdNum: 1,
      table_settings: {
        data: [],
        height: "auto",
        width: "100%",
        autoWrapRow: true,
        autoWrapCol: true,
        rowSelection: true,
        rowHeaders: true,
        selectionMode: "range",
        cells: function () {
          // 禁止编辑
          return {
            readOnly: true,
          };
        },
        afterRemoveRow: (index, amount) => {
          this.adLists.splice(index - 1, amount);
        },
        afterSelectRows: (index) => {
          this.ruleForm = Object.assign(
            this.ruleForm,
            this.adLists[index.row - 1]
          );
        },
        contextMenu: ["remove_row"],
        licenseKey: "non-commercial-and-evaluation",
      },
      interests: [
        {
          id: "6003484127669",
          name: "赌场（博彩）",
        },
        {
          id: "6003237046675",
          name: "老虎机（博彩）",
        },
        {
          id: "6003285625941",
          name: "BlackJack",
        },
        {
          id: "6003248338072",
          name: "休闲Casino",
        },
        {
          id: "6003152880949",
          name: "Mobil Casino",
        },
        {
          id: "6003418127053",
          name: "印度拉米纸牌（游戏）",
        },
        {
          id: "6004175700506",
          name: "TeenPatti（游戏）",
        },
        {
          id: "6002988344794",
          name: "板球（体育运动）",
        },
      ],
      activeKey: "", // 当前选中的key
      showConfigs: false, // 是否显示配置文件
      csvName: "",
      campaign_name_num: 1,
      ad_set_name_num: 1,
      ad_name_num: 1,
      ruleForm: {
        isApp: "H5",
        channel: "UnityMobi",
        optimization_goal: "OFFSITE_CONVERSIONS",
        campaign_type: "广告组预算", // 1:广告组预算 2:广告系列预算
        campaign_name: "",
        campaign_daily_budget: "",
        campaign_start_time: "",
        ad_set_time_start: "",
        ad_account: "",
        ad_set_name: "",
        use_dynamic_creative: "Image",
        ad_set_daily_budget: "20",
        bid_amount: "",
        link_object_id: "", // 配置文件读取
        optimized_conversion_tracking_pixels: "", // 配置文件读取
        link: "",
        countries: "ID",
        gender: "All",
        minAge: "21",
        maxAge: "50",
        flexible_inclusions: [], // 配置文件读取
        publisher_platforms: ["facebook", "instagram"],
        ad_name: "",
        title: "",
        body: "",
        additional_body: [],
        optimize_text_per_person: "Yes",
        conversion_tracking_pixels: "", // 配置文件读取
        resource: [], // 选择的资源
        resource_select: [], // 选中的
        Instagram_account_id: "",
        call_to_action: "DOWNLOAD",
        dynamic_creative_call_to_action: "DOWNLOAD", // 动态时有
      },
      rules: {
        campaign_name: [
          { required: true, message: "请输入广告系列名称", trigger: "blur" },
        ],
        ad_set_name: [
          { required: true, message: "请输入广告组名称", trigger: "blur" },
        ],
        ad_account: [
          { required: true, message: "请选择账户", trigger: "change" },
        ],
        // ad_set_daily_budget: [
        //   { required: true, message: "请输入广告组每日预算", trigger: "blur" },
        // ],
        link_object_id: [
          { required: true, message: "请选择投放主页", trigger: "change" },
        ],
        link: [
          { required: true, message: "请输入投放链接", trigger: "blur" },
          { validator: checkUrl, trigger: "blur" },
        ],
        countries: [
          { required: true, message: "请选择国家", trigger: "change" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        minAge: [
          { required: true, message: "请输入最小年龄", trigger: "blur" },
        ],
        maxAge: [
          { required: true, message: "请输入最大年龄", trigger: "blur" },
          { validator: checkAge, trigger: "blur" },
        ],
        publisher_platforms: [
          { required: true, message: "请选择发布平台", trigger: "change" },
        ],
        ad_name: [
          { required: true, message: "请输入广告名称", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        body: [{ required: true, message: "请输入内容", trigger: "blur" }],
        optimize_text_per_person: [
          { required: true, message: "请选择是否优化", trigger: "change" },
        ],
        resource: [
          { required: true, message: "请选择资源", trigger: "change" },
          { validator: checkResource, trigger: "change" },
        ],
        call_to_action: [
          { required: true, message: "请选择Call to Action", trigger: "blur" },
        ],
        Instagram_account_id: [
          {
            required: true,
            message: "请输入Instagram Account ID",
            trigger: "blur",
          },
        ],
        dynamic_creative_call_to_action: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    showMask() {
      return (
        Object.keys(this.accountsConfig).length === 0 ||
        this.adAssets.length === 0
      );
    },
    dynamic_dis() {
      return this.adLists.length > 0;
    },
    resource_mask() {
      return (item) => {
        const type = this.ruleForm.use_dynamic_creative;
        if (type === "Image") {
          return item.type === "Image";
        } else if (type === "Video") {
          return item.type === "Video";
        } else if (type === "Dynamic") {
          return item.type === "Image" || item.type === "Video";
        }
      };
    },
  },
  created() {
    this.lastAdName = `${this.getDate(0)}.${this.lastAdNum}`;
  },
  methods: {
    onJsonChange(value) {
      // console.log('更改value:', value);
      // 实时保存
      this.onJsonSave(value);
    },
    onJsonSave(value) {
      // console.log('保存value:', value);
      this.accountsConfig = value;
      this.hasJsonFlag = true;
    },
    onError(value) {
      console.log("json错误了value:", value);
      this.hasJsonFlag = false;
    },
    changeType() {
      this.ruleForm.resource = [];
      this.lastAdNum = 1;
      this.selectAssetsActiveItem.forEach((item) => {
        item.isActive = false;
        item.ad_name = 0;
      });
      this.$forceUpdate();
    },
    campaignTypeChange() {
      if (this.ruleForm.campaign_type === "广告组预算") {
        this.ruleForm.campaign_daily_budget = "";
        this.ruleForm.ad_set_daily_budget = "20";
      } else {
        this.ruleForm.ad_set_daily_budget = "";
        this.ruleForm.campaign_daily_budget = "60";
      }
    },
    changeChannel() {
      let account_name = this.accountsConfig.ad_account.find(
        (item) => item.id === this.ruleForm.ad_account
      );
      let name = account_name ? account_name.name : "";
      this.ruleForm.campaign_name = `${this.ruleForm.channel}_${
        this.ruleForm.countries
      }_FB_${name}_${this.accountsConfig.product}_${this.getDate(0)}.${
        this.campaign_name_num
      }`;
    },
    changeCampaignType() {
      if (this.ruleForm.campaign_type === 1) {
        this.ruleForm.campaign_CPA = "";
      } else {
        this.ruleForm.campaign_CPA = "20";
      }
    },
    changeAccount() {
      let account_name = this.accountsConfig.ad_account.find(
        (item) => item.id === this.ruleForm.ad_account
      );
      const name = account_name ? account_name.name : "";
      this.ruleForm.campaign_name = `${this.ruleForm.channel}_${
        this.ruleForm.countries
      }_FB_${name}_${this.accountsConfig.product}_${this.getDate(0)}.${
        this.campaign_name_num
      }`;
    },
    changeCountries() {
      this.ruleForm.channel = "UMTech";
      if (this.ruleForm.countries === "ID") {
        this.ruleForm.channel = "UnityMobi";
      }
      let account_name = this.accountsConfig.ad_account.find(
        (item) => item.id === this.ruleForm.ad_account
      );
      let name = account_name ? account_name.name : "";
      this.ruleForm.campaign_name = `${this.ruleForm.channel}_${
        this.ruleForm.countries
      }_FB_${name}_${this.accountsConfig.product}_${this.getDate(0)}.${
        this.campaign_name_num
      }`;
      if (this.ruleForm.countries === "ID") {
        this.ruleForm.minAge = 21;
      } else {
        this.ruleForm.minAge = 18;
      }
    },
    increase_campaign_name() {
      if (
        Object.keys(this.accountsConfig).length === 0 ||
        !this.adAssets.length
      ) {
        return;
      }
      this.campaign_name_num++;
      let account_name = this.accountsConfig.ad_account.find(
        (item) => item.id === this.ruleForm.ad_account
      );
      let name = account_name ? account_name.name : "";
      this.ruleForm.campaign_name = `${this.ruleForm.channel}_${
        this.ruleForm.countries
      }_FB_${name}_${this.accountsConfig.product}_${this.getDate(0)}.${
        this.campaign_name_num
      }`;
    },
    increase_ad_set_name() {
      if (
        Object.keys(this.accountsConfig).length === 0 ||
        !this.adAssets.length
      ) {
        return;
      }
      this.ad_set_name_num++;
      this.ruleForm.ad_set_name = `${this.getDate(0)}.${this.ad_set_name_num}`;
    },
    add_advert() {
      if (
        Object.keys(this.accountsConfig).length === 0 ||
        !this.adAssets.length
      ) {
        return;
      }
      // 这段代码现在的逻辑是，如果是动态素材，那么只能选择两个素材，如果是图片或者视频，那么可以选择多个素材
      // valid参数是用来判断表单是否验证通过
      this.$refs[`ruleForm${this.activeKey}`].validate((valid) => {
        if (valid) {
          addList.call(this);
          this.ruleForm.link = this.accountsConfig.link;
          this.ruleForm.resource = [];
          // this.campaign_name_num++;
          this.ad_set_name_num++;
          let account_name = this.accountsConfig.ad_account.find(
            (item) => item.id === this.ruleForm.ad_account
          );
          let name = account_name ? account_name.name : "";
          this.ruleForm.campaign_name = `${this.ruleForm.channel}_${
            this.ruleForm.countries
          }_FB_${name}_${this.accountsConfig.product}_${this.getDate(0)}.${
            this.campaign_name_num
          }`;
          this.ruleForm.ad_set_name = `${this.getDate(0)}.${
            this.ad_set_name_num
          }`;
          this.selectAssetsActiveItem.forEach((item) => {
            item.isActive = false;
            item.ad_name = 0;
          });
        } else {
          return false;
        }
      });
      function addList() {
        if (this.ruleForm.use_dynamic_creative === "Dynamic") {
          const newObj = [];
          let obj = this.setItem(this.ruleForm);
          newObj.push(obj);
          let csv = this.jsonToCsv(newObj);
          if (this.table_settings.data.length === 0) {
            this.table_settings.data = this.$papa.parse(csv).data;
          } else {
            this.table_settings.data.push(this.$papa.parse(csv).data[1]);
          }
          this.updateAdvert();
        } else {
          const form = this.ruleForm.resource;
          const newObj = [];
          form.forEach((item, index) => {
            let obj = this.setItem(item, index);
            newObj.push(obj);
          });
          let csv = this.jsonToCsv(newObj);
          if (this.table_settings.data.length === 0) {
            this.table_settings.data = this.$papa.parse(csv).data;
          } else {
            this.$papa.parse(csv).data.forEach((item, index) => {
              if (index > 0) {
                this.table_settings.data.push(item);
              }
            });
          }
          this.updateAdvert();
        }
      }
    },
    // 更新广告预览
    updateAdvert() {
      if (this.handleTable) {
        this.handleTable.destroy();
      }
      const container = document.querySelector("#hot-container");
      this.handleTable = new Handsontable(container, this.table_settings);
      this.handleTable.render();
    },
    addResource(item) {
      if (this.ruleForm.use_dynamic_creative === "Dynamic") {
        if (!item.isActive) {
          if (this.ruleForm.resource.length >= 5) {
            return;
          }
          item.isActive = true;
          item.ad_name = `${this.getDate(0)}.${this.lastAdNum}`;
          this.ruleForm.resource.push(item);
        } else {
          this.removeResource(item);
        }
      } else {
        if (!item.isActive) {
          item.isActive = true;
          item.ad_name = `${this.getDate(0)}.${this.lastAdNum}`;
          this.ruleForm.resource.push(item);
          this.lastAdNum++;
        } else {
          this.removeResource(item);
        }
      }
      this.$forceUpdate();
    },
    removeResource(item) {
      const index = this.ruleForm.resource.indexOf(item);
      item.isActive = false;
      if (index !== -1) {
        this.ruleForm.resource.splice(index, 1);
        if (this.ruleForm.use_dynamic_creative !== "Dynamic") {
          //  重新计算ad_name
          for (let i = 0; i < this.ruleForm.resource.length; i++) {
            if (i >= index) {
              this.ruleForm.resource[i].ad_name = `${this.getDate(0)}.${i + 1}`;
            }
          }
          this.lastAdNum--;
        }
      }
    },
    clearSelectResource() {
      this.ruleForm.resource = [];
      this.adAssets.forEach((assets) => {
        assets.list.forEach((item) => {
          item.isActive = false;
        });
      });
      this.$forceUpdate();
    },
    // 选择账户配置文件
    uploadAccountsConfig(file) {
      let that = this;
      let reader = new FileReader(); //新建一个FileReader
      reader.readAsText(file.raw, "UTF-8"); //读取文件
      reader.onload = (evt) => {
        //读取文件完毕执行此函数
        that.accountsConfig = Object.assign({}, JSON.parse(evt.target.result));
        that.ruleForm.link = that.accountsConfig.link;
        this.ruleForm.ad_account = this.accountsConfig.ad_account[0].id;
        this.ruleForm.link_object_id = this.accountsConfig.pages[0].id;
        let account_name = this.accountsConfig.ad_account.find(
          (item) => item.id === this.ruleForm.ad_account
        );
        let name = account_name ? account_name.name : "";
        that.ruleForm.campaign_name = `${this.ruleForm.channel}_${
          that.ruleForm.countries
        }_FB_${name}_${that.accountsConfig.product}_${that.getDate(0)}.${
          that.campaign_name_num
        }`;
        that.ruleForm.ad_set_name = that.ruleForm.ad_name = `${that.getDate(
          0
        )}.${that.ad_set_name_num}`;
      };
    },
    uploadAssetsConfig(file) {
      let that = this;
      let reader = new FileReader(); //新建一个FileReader
      reader.readAsText(file.raw, "UTF-8"); //读取文件
      reader.onload = (evt) => {
        //读取文件完毕执行此函数
        const json_data = JSON.parse(evt.target.result);
        // 判断是否是多个json文件
        if (json_data.length) {
          let datas = [];
          json_data.forEach((item) => {
            datas = [...datas, ...item.data];
          });
          const uniqueArr = Object.values(
            datas.reduce((acc, curr) => {
              acc[curr.id] = curr;
              return acc;
            }, {})
          );
          that.adAssets = that.groupedItems(uniqueArr);
        } else {
          that.adAssets = that.groupedItems(JSON.parse(evt.target.result).data);
        }
        that.selectResourceItem(0);
        // console.log(that.groupedItems(JSON.parse(evt.target.result).data));
      };
    },
    groupedItems(list) {
      let group_list = [];
      list.forEach((item) => {
        const date = this.formatDateTime(
          new Date(item.creation_time),
          "yyyy-MM-dd"
        );
        const index = group_list.findIndex((i) => i.date === date);
        item["ad_name"] = 0;
        item["isActive"] = false;
        if (index === -1) {
          group_list.push({
            date,
            list: [item],
          });
        } else {
          group_list[index].list.push(item);
        }
      });
      return group_list;
    },
    selectResourceItem(index) {
      this.selectAssetsActiveKey = index;
      this.selectAssetsActiveItem = this.adAssets[index].list;
    },

    exportCSV() {
      if (this.table_settings.data.length < 2) {
        return;
      }
      let csv = this.jsonToCsv(this.table_settings.data);
      // let time = this.getDate(0);
      this.$papa.download(csv, `ad_config${this.ruleForm.ad_account}`);
      const ruleForm_copy = JSON.parse(JSON.stringify(this.ruleForm));
      this.$refs[`ruleForm${this.activeKey}`].resetFields();
      this.adLists = [];
      this.table_settings.data = [];
      if (this.handleTable) {
        this.handleTable.destroy();
      }
      this.handleTable = null;
      // this.campaign_name_num++;
      // this.ad_set_name_num++;
      this.ruleForm = ruleForm_copy;
      let account_name = this.accountsConfig.ad_account.find(
        (item) => item.id === this.ruleForm.ad_account
      );
      let name = account_name ? account_name.name : "";
      this.ruleForm.campaign_name = `${this.ruleForm.channel}_${
        this.ruleForm.countries
      }_FB_${name}_${this.accountsConfig.product}_${this.getDate(0)}.${
        this.campaign_name_num
      }`;
      this.ruleForm.ad_set_name = `${this.getDate(0)}.${this.ad_set_name_num}`;
    },

    // 设置各个需要的参数
    setItem(item) {
      const that = this;
      let start_time = that.formatDateTime(new Date(), "MM/dd/yyyy hh:mm");
      let newData = Object.assign({}, defaultData);
      let submit_form = this.ruleForm;
      const interest = [
        {
          interests: [],
        },
      ];
      submit_form.flexible_inclusions.forEach((item) => {
        const item1 = this.interests.find((i) => i.id === item);
        interest[0].interests.push({
          id: item1.id,
          name: item1.name,
        });
      });
      // 如果是广告系列预算，那么就不用设置广告组预算，否则就设置广告组预算
      if (submit_form.campaign_type === "广告组预算") {
        newData["Campaign Daily Budget"] = "";
        newData["Ad Set Daily Budget"] = submit_form.ad_set_daily_budget;
      } else {
        newData["Campaign Daily Budget"] = submit_form.campaign_daily_budget;
        newData["Ad Set Daily Budget"] = "";
      }
      if (submit_form.bid_amount) {
        newData["Bid Amount"] = submit_form.bid_amount;
      }
      newData["Campaign Name"] = submit_form.campaign_name;
      newData["Campaign Start Time"] = start_time;
      newData["Ad Set Name"] = submit_form.ad_set_name;
      newData["Ad Set Time Start"] = start_time;
      newData["Link"] = submit_form.link;
      newData["Link Object ID"] = "o:" + submit_form.link_object_id;
      newData["Countries"] = submit_form.countries;
      if (submit_form.gender !== "All") {
        newData["Gender"] = submit_form.gender;
      }
      newData["Age Min"] = submit_form.minAge;
      newData["Age Max"] = submit_form.maxAge;
      newData["Flexible Inclusions"] = JSON.stringify(interest);
      newData["Publisher Platforms"] = submit_form.publisher_platforms;
      // app广告添加的值
      if (submit_form.isApp === "APP") {
        newData = Object.assign({}, newData, appData);
        newData["Campaign Objective"] = "App promotion";
        newData["Call to Action"] = "INSTALL_MOBILE_APP";
        newData["Optimization Goal"] = submit_form.optimization_goal;
        newData["Application ID"] = this.accountsConfig.appid;
        newData["Object Store URL"] = submit_form.link;
        if (submit_form.optimization_goal === "OFFSITE_CONVERSIONS") {
          newData["Optimized Event"] = "PURCHASE";
          newData["Attribution Spec"] =
            '[{"event_type":"CLICK_THROUGH","window_days":7}]';
        } else {
          newData["Attribution Spec"] =
            '[{"event_type":"CLICK_THROUGH","window_days":1},{"event_type":"VIEW_THROUGH","window_days":1},{"event_type":"ENGAGED_VIDEO_VIEW","window_days":0}]';
        }
      } else {
        newData["Call to Action"] = submit_form.call_to_action;
        newData["Conversion Tracking Pixels"] =
          "tp:" + this.accountsConfig.pixel_id;
        newData["Optimized Conversion Tracking Pixels"] =
          "tp:" + this.accountsConfig.pixel_id;
      }
      // 动态广告添加的值
      if (submit_form.use_dynamic_creative === "Dynamic") {
        newData = Object.assign({}, newData, dynamicData);
        newData["Ad Name"] = submit_form.resource[0].ad_name;
        if (submit_form.resource[0].type === "Video") {
          newData["Video ID"] = "v:" + submit_form.resource[0].video_id;
          submit_form.resource.forEach((item1, index) => {
            if (index > 0) {
              newData[
                `Additional Video ${index} ID`
              ] = `${submit_form.ad_account}:v:${item1.video_id}`;
              newData[`Additional Video ${index} Thumbnail URL`] =
                item1.thumbnail;
            }
          });
        } else {
          newData["Image Hash"] = submit_form.resource[0].hash;
          newData["Dynamic Creative Call to ActionCall"] =
            submit_form.dynamic_creative_call_to_action;
          submit_form.resource.forEach((item1, index) => {
            if (index > 0) {
              newData[
                `Additional Image ${index} Hash`
              ] = `${submit_form.ad_account}:${item1.hash}`;
            }
          });
        }
      } else {
        newData["Ad Name"] = item.ad_name;
        if (submit_form.use_dynamic_creative === "Video") {
          newData = Object.assign({}, newData, videoData);
          newData["Video ID"] = "v:" + item.video_id;
          newData["Video File Name"] = item.name;
          newData["Video Thumbnail URL"] = item.thumbnail;
        } else {
          newData = Object.assign({}, newData, imageData);
          newData["Image Hash"] = `${submit_form.ad_account}:${item.hash}`;
          newData["Image File Name"] = item.name;
        }
      }
      return newData;
    },

    getDate(num) {
      var date = new Date();
      date.setDate(date.getDate() + num);
      //当使用setDate()之后，date对象就发生了改变，所以之后getDate()就能获取到指定的日期
      //这里的num就是你要加的天数，减也可以。
      // let year = date.getFullYear(); //年
      let month = date.getMonth() + 1; //月
      let day = date.getDate(); //天数
      if (month >= 1 && month <= 9) {
        month = `${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `${day}`;
      }
      return `${month}.${day}`;
    },
    formatDateTime(date, format) {
      const o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
        "H+": date.getHours(), // 小时
        "m+": date.getMinutes() + 2, // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
        a: date.getHours() < 12 ? "上午" : "下午", // 上午/下午
        A: date.getHours() < 12 ? "am" : "pm", // AM/PM
      };
      if (/(y+)/.test(format)) {
        format = format.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return format;
    },
    jsonToCsv(jsonData) {
      return this.$papa.unparse(jsonData);
    },
  },
};
</script>
<style>
.jsoneditor-vue {
  height: 100%;
}
.el-container {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  height: 100vh;
}
.el-input {
  width: 100%;
}
.head_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.el-main {
  padding: 20px 10% !important;
  width: 100%;
  flex-direction: column;
}
.el-form {
  position: relative;
  width: 600px;
  padding: 20px;
}
.mask_nav {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(128, 128, 128, 0.5);
}

.el-main >>> .el-select,
.el-radio-group {
  width: 100%;
}
.el-main >>> .el-radio-group {
  display: flex;
  align-items: center;
  height: 40px;
}

.demo-ruleForm {
  width: 50%;
  padding-top: 50px;
}

.a-upload {
  padding: 4px 10px;
  height: 20px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
  color: #eee;
  font-size: 12px;
  background: rgba(107, 114, 128, 0.5);
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  text-decoration: none;
}

.a-upload input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}

.a-upload:hover {
  color: #444;
  background: #eee;
  border-color: #ccc;

  text-decoration: none;
}

.file {
  position: relative;
  display: inline-block;
  background: #d0eeff;
  border: 1px solid #99d3f5;
  border-radius: 4px;
  padding: 4px 12px;
  overflow: hidden;
  color: #1e88c7;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
}

.file input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}

.file:hover {
  background: #aadffd;
  border-color: #78c3f3;
  color: #004974;
  text-decoration: none;
}

.action_nav {
  display: flex;
  justify-content: start;
  padding-left: 100px;
}
.action_nav > div {
  margin-right: 20px;
}
.el-form-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
}
.el-form-item__content {
  flex: 1;
  margin-left: 0 !important;
  display: flex;
  width: 100%;
}

.add_form_item {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.add_form_item >>> .el-form-item {
  margin-bottom: 0;
}
.add_form_item_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
.el-footer {
  height: 160px !important;
}
.fz12 {
  font-size: 12px;
}
.resource_nav_out {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  height: 690px;
  border: 1px solid #eeeeee;
  position: relative;
}
.resource_nav_out .resource_btn_nav {
  width: 100%;
  background-color: #eeeeee;
  right: 0;
  top: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.resource_content_nav {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.resource_nav_left {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  background-color: #eeeeee;
  width: 120px;
  > span {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
  }
  .active {
    color: #409eff;
    font-weight: bold;
    background-color: rgba(64, 158, 255, 0.1);
    border-radius: 10px;
  }
}
.resource_nav_left::after {
  content: "日期";
  position: absolute;
  left: 0;
  top: 0;
  height: 52px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #409eff;
}
.resource_nav_list {
  display: grid;
  //grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  width: 100%;
  grid-auto-rows: 160px;
  height: 600px;
  overflow-y: scroll;
}
.resource_nav {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  .close_nav {
    display: flex;
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
    z-index: 10;
  }
  span {
    white-space: nowrap;
    font-size: 12px;
  }
}
.show_ad_name {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  z-index: 10;
  height: 40px;
}
.show_ad_name > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: green;
  border-radius: 10px 10px 0 0;
  width: 100px;
  height: 20px;
}
.el-aside {
  padding: 0;
  width: auto !important;
  flex: 1;
  min-width: 600px;
  margin-bottom: 38px;
}
/*
 *::-webkit-scrollbar {
  width: 0.1em;
  height: 0.1em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
}
 */
.el-main::-webkit-scrollbar,
.resource_nav_list::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
.el-main::-webkit-scrollbar-thumb,
#hot-container::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  //box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
}
.el-main::-webkit-scrollbar-track,
#hot-container::-webkit-scrollbar-track {
  border-radius: 8px !important;
  background-color: #fff !important;
  border: 1px solid #fff !important;
}
.el-main::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(180deg, #708ad4 0%, #d0368a 99%);
}
.cu_pointer {
  cursor: pointer;
}
.img_class {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.el-radio-group {
  display: flex !important;
  flex-wrap: wrap;
}
.el-radio-button {
  margin-top: 10px;
}
h2 {
  color: white;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black;
  animation: rainbow-animation 200ms linear infinite;
}
@keyframes rainbow-animation {
  to {
    filter: hue-rotate(0deg);
  }
  from {
    filter: hue-rotate(360deg);
  }
}
</style>

<script setup></script>
