export const defaultData = {
  "Campaign Status": "ACTIVE",
  "Campaign Objective": "Outcome Sales",
  "Buying Type": "AUCTION",
  "New Objective": "Yes",
  "Ad Set Run Status": "ACTIVE",
  "Ad Set Lifetime Budget": "0",
  "Ad Set Lifetime Impressions": "0",
  "Additional Custom Tracking Specs": "[]",
  "Destination Type": "UNDEFINED",
  "Use Accelerated Delivery": "No",
  "Location Types": "home, recent",
  "Publisher Platforms": "facebook, instagram",
  "Device Platforms": "mobile, desktop",
  "User Operating System": "Android",
  "Brand Safety Inventory Filtering Levels": "FACEBOOK_STANDARD",
  "Optimization Goal": "OFFSITE_CONVERSIONS",
  "Attribution Spec":
    '[{ event_type: "CLICK_THROUGH", window_days: 7 },{ event_type: "VIEW_THROUGH", window_days: 1 },]',
  "Billing Event": "IMPRESSIONS",
  "Ad Set Bid Strategy": "Highest volume or value",
  "Ad Status": "ACTIVE",
  "Optimized Ad Creative": "No",
  "Use Page as Actor": "No",
  "Video Retargeting": "No",
};

export const videoData = {
  "Targeting Relaxation": "lookalike: Off, custom_audience: Off",
  "Creative Type": "Video Page Post Ad",
  "Facebook Positions":
    "feed, instream_video, right_hand_column, video_feeds, marketplace, story, facebook_reels_overlay, search, facebook_reels",
  "Instagram Positions":
    "stream, story, explore, explore_home, reels, reels_overlay, profile_feed, ig_search",
  "Optimize text per person": "No",
  "Optimized Event": "PURCHASE",
  "Degrees of Freedom Type": "USER_ENROLLED_AUTOFLOW",
};
export const imageData = {
  "Text Transformations": "TEXT_LIQUIDITY",
  "Creative Type": "Link Page Post Ad",
  "Facebook Positions":
    "feed, instream_video, right_hand_column, video_feeds, marketplace, story, facebook_reels_overlay, search, facebook_reels",
  "Instagram Positions":
    "stream, story, explore, explore_home, reels, reels_overlay, profile_feed, ig_search",
  "Optimize text per person": "No",
  "Optimized Event": "PURCHASE",
  "Degrees of Freedom Type": "USER_ENROLLED_AUTOFLOW",
};
export const dynamicData = {
  "Text Transformations": "TEXT_LIQUIDITY",
  "Creative Type": "Link Page Post Ad",
  "Facebook Positions":
    "feed, facebook_reels, facebook_reels_overlay, right_hand_column, video_feeds, instream_video, marketplace, story, search",
  "Use Dynamic Creative": "Yes",
  "Instagram Positions": "stream, story, explore, reels, profile_feed",
  "Optimize text per person": "Yes",
  "Optimized Event": "PURCHASE",
  "Dynamic Creative Additional Optimizations": "Yes",
  "Degrees of Freedom Type": "USER_ENROLLED",
  "Dynamic Creative Ad Format": "Automatic Format",
};
export const appData = {
  "Destination Type": "APP",
};
